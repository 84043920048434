

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/avocat-travail-syndicat-convention.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost10 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Avocat en droit du travail pour la négociation de convention collective, accréditation et mise en place d’un syndicat ! - Soumissions Avocat"
        description="Consultez un avocat spécialisé en droit du travail pour négocier une convention collective ou mettre en place un syndicat! Trouvez votre professionnel avec Soumissions Avocat!"
        image={LeadImage}>
        <h1>Avocat en droit du travail pour la négociation de convention collective, accréditation et mise en place d’un syndicat !</h1>

					
					
						<p><strong>Les syndicats font partie intégrante de la sphère du travail au Québec.</strong> Dans la fonction publique comme privée, nombreux sont les milieux de travail où les relations entre l’employeur et ses salariés sont encadrées par des syndicats. Que vous soyez du côté employeur ou employé, la syndicalisation affectera grandement votre réalité quotidienne au travail, en plus d’amener son lot de droits et d’obligations pour toutes les personnes impliquées!</p>
<p><StaticImage alt="avocat travail syndicat convention" src="../images/avocat-travail-syndicat-convention.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Il est donc essentiel de comprendre le processus d’accréditation d’association, de mise en place de syndicat et de négociation de convention collective avec un avocat en droit du travail!  À quoi ressemblera votre situation professionnelle une fois le syndicat en place? Aurez-vous toujours le droit de négocier vos conditions de travail directement avec votre employeur?</p>
<p><strong>Soumissions Avocat répond à toutes vos questions sur la négociation de convention collective et l’accréditation d’associations syndicales au Québec!</strong></p>
<h2>Les droits des employés et des gestionnaires lors du processus de syndicalisation</h2>
<p>La syndicalisation est un sujet délicat. Les relations peuvent vite devenir tendues entre un entrepreneur réticent à la négociation collective et les employés en pleine démarche d’accréditation. Ceci dit, toutes les parties devront mettre de l’eau dans leur vin, puisque la loi est très sévère en termes d’encadrement des relations de travail.</p>
<p><strong>Tout d’abord, le droit d’association est protégé par la Charte canadienne des droits et libertés. </strong>En effet, l’article 2 de cette même Charte confère à tous le droit à la liberté d’association, protection qui s’étend évidemment au domaine des relations de travail.</p>
<p><strong>En matière de relations collectives de travail, un employeur ne peut donc pas intervenir pour empêcher l’exercice de ce droit. </strong>Quel que soit le secteur d’activité, le domaine spécifique de l’entreprise ou autre, un employeur ne peut en aucun cas intervenir pour limiter le droit de ses salariés de mettre en place un syndicat ou encore d’adhérer à celui qui est déjà en place.</p>
<p>Un employé peut-il être obligé de faire partie d’une association? Non, en aucun cas un employé ne peut être forcé de faire partie d’un syndicat. Toutefois, il peut être obligé de payer les cotisations syndicales (ou retenues syndicales), même s’il refuse de faire partie officiellement de l’association.</p>
<p><strong>Pourquoi un employé refuserait-il de faire partie du syndicat, dans ce cas?</strong> Il s’agit d’une question personnelle dont la réponse dépend des valeurs de cet employé. Puisque celui-ci devra tout de même payer les cotisations syndicales, le refus de faire partie de l’association ne fera que l’empêcher de profiter de la représentation du syndicat en temps opportun.</p>
<p><strong>Il ne s’agit là que de la base des droits d’association. Voyez plus bas comment fonctionne la mise en place d’un syndicat par le processus d’accréditation et de négociation de convention collective avec un avocat!</strong></p>
<h2>Comment se déroule le processus d’accréditation d’un syndicat au Québec?</h2>
<p>Pour mettre en place un syndicat, une association de salariés doit avant tout déposer une demande d’accréditation auprès du Tribunal administratif du travail. Seule une association accréditée sera en droit de négocier une convention collective avec l’employeur, et c’est pourquoi il s’agit d’une première étape de la plus haute importance.</p>
<p><StaticImage alt="accreditation association salaries syndicat" src="../images/accreditation-association-salaries-syndicat-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Qui peut déposer une demande d’accréditation?</strong> Seule une association de salariés est en droit de déposer une demande d’accréditation au Tribunal administratif du travail. Cette association peut prendre n’importe quelle forme et bien que plusieurs d’entre elles soient représentées par des centrales syndicales, sachez que rien n’oblige un tel fonctionnement.</p>
<p>Notez également que ce ne sont que les regroupements de salariés qui peuvent demander l’accréditation. <strong>Les cadres d’une entreprise</strong> ne peuvent jamais être inclus dans une unité de négociation! En effet, comme la syndicalisation vise à augmenter le pouvoir de négociation des salariés face à l’employeur, les représentants de l’employeur comme les cadres de l’entreprise ne peuvent pas faire partie de la demande. Le statut de cadre est d’ailleurs une question de fait.</p>
<p>Qu’est-ce que la requête en accréditation doit contenir? La demande présentée doit d’abord identifier le nom de l’association requérante et celui des associations déjà accréditées. Ensuite, la requête doit définir clairement l’unité de négociation visée, soit le groupe de salarié au nom de qui l’association négociera face à l’employeur.
<strong>
L’unité de négociation peut poser problème dès le départ!</strong> En effet, cette unité doit représenter un groupe distinct au sein de l’entreprise, lequel partage des intérêts communs. Cela vise à faciliter la négociation collective face à l’employeur, mais pour établir une unité de négociation, il doit y avoir accord avec l’employeur sur la définition de celle-ci. C’est ici que le bât blesse parfois!</p>
<p>Un employeur ne peut pas s’opposer au simple fait d’instaurer une association, mais il peut s’opposer aux salariés qui en font partie s’il juge qu’ils ne partagent pas suffisamment d’intérêts de négociation communs. Par exemple, le personnel travaillant sur le plancher de l’usine et celui travaillant dans les bureaux n’auront probablement pas les mêmes requêtes à formuler auprès de l’employeur, d’où l’intérêt de les séparer en deux unités.</p>
<h2><strong>À quel moment une requête en accréditation peut-elle être déposée? </strong></h2>
<p>Une fois l’unité définie (avec l’accord des salariés et de l’employeur), il faut se pencher sur le moment où le dépôt d’une demande est opportun. En l’absence d’une association existante, la requête peut être déposée en tout temps. Lorsqu’une association est en place depuis 12 mois, mais inefficace, et qu’elle n’a toujours pas conclu de convention collective, il est également possible de déposer une requête.</p>
<p>De plus, à chaque fois qu’une convention collective arrive à échéance, le droit de déposer une requête s’ouvre pendant une certaine période de maraudage. Pour une convention collective de 3 ans ou moins, la requête peut être déposée entre le 90e et 60e jour avant l’expiration de celle-ci.</p>
<p>Lorsque la convention est d’une durée d’entre 3 ans et 6 ans, la période de maraudage s’ouvre entre le 180e et 150e jour précédant la date d’expiration, alors que pour une convention de plus de 6 ans, c’est entre le 180e et 150e jour précédant le 6e anniversaire de la convention et une nouvelle période de maraudage s’ouvre tous les deux ans par la suite.</p>
<p>Qu’arrive-t-il pendant la requête en accréditation? Pendant le dépôt d’une requête en accréditation, l’employeur est obligé de maintenir les conditions de travail dans leur état actuel. Il ne peut en aucun cas modifier, améliorer ou autrement changer les conditions de travail sous peine de sanction.</p>
<p><strong>Que se passe-t-il une fois l’association accréditée? </strong>Une fois l’association accréditée, un avis de négociation devra être envoyé par l’employeur ou par l’association afin d’amorcer la négociation du contenu de la convention collective.</p>
<h2>Les étapes de la négociation et signature d’une convention collective avec un avocat!</h2>
<p>Le moment tant attendu, la négociation de la convention collective entre l’employeur et l’association de salariés! En matière de négociation, les parties sont toutes deux tenues à une obligation de bonne foi lors des négociations. Cette obligation perdure jusqu’à la signature d’une convention collective. Celui qui négocie de mauvaise foi s’expose d’ailleurs à des sanctions pénales.</p>
<p><StaticImage alt="avocat negociation convention collective" src="../images/avocat-negociation-convention-collective-scaled.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Pour ce qui est du contenu de la convention collective, tout commence avec l’envoi d’un avis de négociation! </strong>Il peut provenir de l’employeur ou de l’association de salariés, mais il doit absolument être envoyé, puisqu’il marque le début de l’obligation de négocier. De plus, c’est la date de l’envoi de l’avis qui dicte le moment d’acquisition du doit à la grève ou au lock-out.</p>
<p>L’avis doit d’ailleurs être envoyé 8 jours avant la date stipulée pour la tenue des négociations, et c’est à ce moment que les parties doivent tenter de négocier, de bonne foi, le contenu de convention collective. En cas de désaccord, des mécanismes de médiation et de conciliation sont également offerts afin de rapprocher les intérêts divergents.</p>
<p><strong>Qu’est-ce que l’arbitrage de différend et comment aide-t-elle à la conclusion d’une convention collective?</strong> L’arbitrage de différend survient lorsque les parties à la négociation sont incapables de s’entendre. Lors d’une première convention collective, l’arbitrage peut être obligatoire et demandé par une seule des parties.</p>
<p>Lorsqu’il s’agit d’une seconde ou énième convention, l’arbitrage doit obligatoirement être demandé conjointement par les deux parties. Dans tous les cas, la convention collective doit durer au moins 1 an et la signature de la convention devra être autorisée au scrutin secret.</p>
<p><strong>Quels sont les moyens de pression que les parties peuvent appliquer entre elles? </strong>L’employeur peut déclencher un lock-out après 90 jours suivant la réception de l’avis de négociation, tandis que l’association de salariés dispose du même droit, sauf que son recours sera plutôt celui de la grève.</p>
<p><strong>Négocier une convention collective requiert le respect d’une stratégie élaborée par un avocat qualifié en droit du travail. Il s’agit parfois d’une démarche de longue haleine, surtout si la grève ou le lock-out s’éternise. Une raison de plus de consulter un avocat spécialisé!
</strong></p>
<h2>Qu’est-ce qu’une convention collective complète devrait contenir?</h2>
<p>Avant d’entamer les négociations, l’employeur comme l’association de salariés devraient tous deux prendre le temps d’analyser ce qu’ils souhaitent inclure dans la convention collective. Comme la celle-ci sera la « loi des parties » pendant les prochaines années à venir, il serait avantageux pour tous de partir sur une base solide!</p>
<p><strong>D’abord, la convention collective doit obligatoirement être écrite!</strong> Alors qu’un contrat peut habituellement être conclu valablement de façon verbale, le convention collective fait exception à ce principe, puisque la loi exige qu’elle soit constatée dans un écrit signé par l’association et par l’employeur.</p>
<p>De plus, une convention collective dument négociée devrait toujours contenir les aspects suivants!</p>
<p>-La reconnaissance du droit de gestion de l’employeur</p>
<p>-Les horaires de travail</p>
<p>-Les conditions de travail (congés, vacances, temps supplémentaire, etc.)</p>
<p>-Le salaire des employés/l’échelle salariale</p>
<p>-Les sanctions et mesures disciplinaires</p>
<p>-Le mode de règlement des griefs</p>
<p><strong>Les dispositions de la convention ne peuvent par contrevenir à l’ordre public! </strong>Bien qu’une convention puisse porter sur l’ensemble des éléments mentionnés ci-haut, elle ne peut en aucun cas déroger aux principes d’ordre public en limitant la liberté d’association des salariés en vertu de la Charte canadienne.</p>
<h2>L’arbitrage de griefs est-il obligatoire en vertu d’une convention collective ?</h2>
<p><strong>La mise en place d’une convention collective met fin aux relations de travail individuelles. </strong>Ce faisant, l’arbitrage de griefs est donc la procédure obligatoire puisque l’association et l’employeur se sont entendus pour tous deux recourir à l’arbitrage de griefs en vertu de la convention.</p>
<p><StaticImage alt="arbitrage griefs travail avocat" src="../images/arbitrage-griefs-travail-avocat.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Dans quelles circonstances l’arbitrage est-il nécessaire et obligatoire? </strong>Dès qu’une mésentente survient quant à l’interprétation, l’application ou autre disposition de la convention, c’est l’arbitrage de griefs qui devient nécessaire.</p>
<p>Ce principe a d’ailleurs une portée très large et ne se limite pas simplement à l’application des clauses de la convention. Dès que la convention est moindrement concernée par la mésentente entre l’employeur et un salarié, c’est l’arbitrage de griefs qui s’applique et non les tribunaux de droit commun.</p>
<h2>Quelles sont les conséquences de la mise en place d’un syndicat au sein d’une entreprise?</h2>
<p>L’instauration d’un syndicat dans le milieu de travail vient avec des conséquences spécifiques, aussi bien pour les employés que pour l’employeur. Le premier effet de la mise en place d’un syndicat est qu’elle met un terme aux relations individuelles de travail entre l’employeur et ses employés. Qu’est-ce que cela implique?</p>
<p>Premièrement, les employés <strong>ne peuvent plus négocier directement</strong> avec le patronat. Que le sujet de négociation tourne autour du salaire, de l’horaire, de la prestation ou de tout autre aspect des relations de travail, désormais, tout passe par le syndicat et par la convention collective. Les employés n’ont plus le droit de négocier directement avec l’employeur, et vice versa.</p>
<p>De plus, lors d’un <strong>conflit entourant l’application de la convention</strong>, celui-ci devra être réglé devant l’arbitre de grief. Par exemple, advenant que l’employeur sanctionne un salarié pour un motif disciplinaire, celle-ci devra être portée devant un arbitre de grief si le salarié désire contester la sanction. Le grief, tel que mentionné précédemment, est la procédure de règlement de conflit obligatoire.</p>
<p><strong>La retenue syndicale est également obligatoire</strong>! Même si le salarié ne fait pas partie de l’association (par choix), s’il fait partie de l’unité de négociation, il est désormais tenu de payer sa cotisation syndicale.</p>
<p><strong>Finalement, le syndicat demeure en place, même en cas de vente de l’entreprise! </strong>Que l’entreprise soit vendue ou cédée à un nouveau propriétaire, la convention demeure en vigueur et le syndicat conserve son accréditation. Le nouveau patron doit désormais négocier avec le syndicat en place et honorer la convention.</p>
<p>L’accréditation d’une association en tant que syndicat est loin d’être une étape banale. Les droits des parties prenantes sont grandement impactés par l’instauration d’une association de salariés, alors contactez un avocat pour en apprendre davantage sur les conséquences de la syndicalisation.</p>
<h2>Tous les services d’avocat en matière de relations de travail collectives!</h2>
<p>Les libertés fondamentales, l’accréditation, la négociation de convention collective, l’arbitrage de grief, etc. La mise en place d’un syndicat en aura amené des nouvelles facettes dans vos relations de travail n’est-ce pas! Sachant cela, il est évident que vous avez tout intérêt à solliciter un avocat pour vous accompagner dans toutes les étapes des relations de travail collectives, dont les suivantes!</p>
<p><strong>Accompagner les employés et les dirigeants lors de l’accréditation. </strong>Entamer la démarche d’accréditation nécessite de définir l’unité de négociation, le tout en obtenant l’approbation de l’employeur. En cas de contestation de la part de ce dernier sur les employés visés, la représentation légale pourra contribuer à dénouer l’impasse.</p>
<p><strong>Négociation et rédaction de convention collective. </strong>Une fois l’avis de négociation envoyé, le temps est venu de négocier! Le contenu de la convention est parfois complexe et toujours exhaustif. Négocier des conditions équitables pour tous est une spécialité des avocats en droit du travail.<strong>
</strong>
<strong>Représentation lors de griefs ou de recours. </strong>Pour l’employeur, il est essentiel d’être conseillé par un avocat familier avec le contenu de la convention collective en cas de grief, puisque ce document fait office de loi des parties entre vous et vos salariés. La représentation par avocat est essentielle pour vous défendre contre les allégations ou contestations de vos employés.</p>
<h2>Trouvez un avocat spécialisé en droit du travail pour vos besoins syndicaux!</h2>
<p>Vous désirez améliorer vos conditions de travail par l’entremise d’un syndicat? Vous êtes plutôt un employeur qui vient de recevoir un avis signifiant la mise en place d’une association ou annonçant le début des négociations? Dans un cas comme dans l’autre, l’expertise unique de l’avocat en droit du travail vous est indispensable pour faire valoir vos droits!</p>
<p>Les relations entre l’employeur et ses employés sont bien souvent à leur plus fragile lors d’une négociation de convention collective. Pour préserver ces liens professionnels et négocier une entente équitable portant sur les relations de travail, il est indispensable d’encadrer la démarche avec un professionnel du droit compétent.</p>
<p><strong>Soumissions Avocat peut rapidement vous trouver un tel partenaire afin d’amorcer et conclure vos démarches de syndicalisation, que vous soyez un employeur ou un représentant des employés.</strong></p>
<p><strong>Vous n’avez qu’à remplir notre formulaire en bas de page pour être mis en contact gratuitement avec un avocat en droit du travail dans votre région!</strong></p>
    </SeoPage>
)
export default BlogPost10
  